import React, { Component} from 'react'
import Slideshow from '../layouts/Slideshow'
import { Carousel } from 'react-bootstrap';
import "./grid.css";

class Home extends Component {

    handleSubmit= (e) => {
        e.preventDefault(window.location.reload());
        // console.log(window.location.reload())
    }

    componentDidMount() {
    
    var navbarShrink = function () {
        const navbarCollapsible = document.body.querySelector('#mainNav');
        if (!navbarCollapsible) {
            return;
        }
        if (window.scrollY === 0) {
            navbarCollapsible.classList.remove('navbar-shrink')
        } else {
            navbarCollapsible.classList.add('navbar-shrink')
        }

    };
    // Shrink the navbar 
    navbarShrink();
    // Shrink the navbar when page is scrolled
    document.addEventListener('scroll', navbarShrink);

    }

    render() {   
        return (
            <>
                <section className="home1-section" id="mainNav">
                        <header className="masthead">
                                <div className="container-fluid text-center" align="center">
                                    <div className="text-center">
                                        <span className="textstyle10">ฟีเจอร์</span>
                                        <p className="wow fadeInUp" data-wow-delay="0.8s" >ฟีเจอร์บางส่วนของโปรแกรม<br/> 
                                        <font style={{color:'Red'}}>ส่งประวัติการรักษาคนไข้ไปมาระหว่างคลินิกได้ </font><br/>
                                        บริหารงานคลินิกโดยไม่เข้าคลินิกก็รับทราบรายงานต่างๆได้<br/>
                                        มีระบบ Paperless ช่วยแก้ปัญหาการใช้เอกสารในคลินิก เช่น แฟ้ม OPD Card และแผนการรักษาเป็นต้น
                                        </p>
                                    </div>
                                </div>
                        </header>
                        {/* <div class="col-md-offset-2 col-md-12 col-sm-12" align="center">
                            <div class="section-title">
                                <span class="textstyle10">หน้าจอแอป</span>
                                <p class="wow fadeInUp" data-wow-delay="0.8s">ตัวอย่างหน้าจอบางส่วนของโปรแกรม <br />
                                    ใช้ง่ายครบทุกฟังก์ชัน ประสบการณ์มากกว่า 10 ปี มีใช้ในคลินิกทั่วประเทศ
                                </p>
                            </div>
                        </div> */}
    
                        <div className="container">
                            <div className="row">

                            <div className="wow fadeInUp col-sm-12 col-md-4 col-xs-12 col-lg-4" data-wow-delay="0.4s">
                                <div className="about-thumb hvr-glow">
                                    <div className="about-overlay">
    
                                        <div className="card">
                                            <div className="card-header">
                                                <img className="mx-auto d-block" src="assets/images/icon-img1.png" alt="" />
                                            </div>
    
                                            <div className="card-body">
                                                <span className="wow fadeInUp">
                                                    <h5 className="card-title" align="center">ประวัติการรักษา</h5>
                                                    <span className="textstyle21Kanit" style={{'text-align':'left'}} >
                                                        ประวัติการรักษาเก่า การค้นหาคนไข้ ภาพก่อนจัดฟัน ใบOPD โปรโมชั่นต่างๆของลูกค้า ระบบคิว<font style={{ color: '#FF5500'}}> บันทึกข้อมูลโดยไม่ต้องคีร์ข้อมูลจากเครื่องอ่านบัตรประชาชน</font>และค้นหาข้อมูลรวดเร็วโดยเครื่องอ่านบาร์โค๊ด,เครื่องอ่านบัตรปชช.,เครื่องสแกนลายนิ้วมือ
                                                        บันทึกรูปไม่จำกัด
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                            {/* ---------------------------------------------------------------------------------------------- */}
                            <div className="wow fadeInUp col-md-4 col-xs-12 col-sm-12 col-lg-4" data-wow-delay="0.4s">
                                <div className="about-thumb hvr-glow">
                                    <div className="about-overlay">
    
                                        <div className="card">
                                            <div className="card-header">
                                                <img className="mx-auto d-block" src="assets/images/icon-img2.png" alt="" />
                                            </div>
                                            <div className="card-body">
                                                <span className="wow fadeInUp">
                                                    <h5 className="card-title" align="center">ตารางการรักษา </h5>
                                                    <span className="textstyle21Kanit" style={{'text-align':'left'}}>
                                                        แผนการรักษาและวินิจฉัยของทันตแพทย์ทำเห็นภาพรวมการรักษาทั้งหมด <b>บันทึกง่ายและรวดเร็วโดยเลือกตำแหน่งฟันและบริการ</b> มีภาพสัญลักษณ์แทนการรักษาและสีบอกสถานะรายการรักษาและสามารถลิ้งกับเครื่องเอกซเรย์เพื่อดูรูปภาพต่างๆ เขียนชาร์ตฟัน
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                            {/* ---------------------------------------------------------------------------------------------- */}
                            <div className="wow fadeInUp col-md-4 col-xs-12 col-sm-12 col-lg-4" data-wow-delay="0.4s">
                                <div className="about-thumb hvr-glow">
                                    <div className="about-overlay" >
    
                                        <div className="card">
                                            <div className="card-header">
                                                <img className="mx-auto d-block" src="assets/images/icon-img3.png" alt="" />
                                            </div>
                                            <div className="card-body">
                                                <span className="wow fadeInUp">
                                                    <h5 className="card-title" align="center">ห้องทันตกรรม</h5>
                                                    <span className="textstyle21Kanit" style={{'text-align':'left'}}>
                                                        บันทึกการรักษาหรือบริการ ระบบคิว เวชภัณฑ์ การวินิจฉัย Lab ประวัติการรักษา <b>ฟิลม์เชื่อมกับโปรแกรม X-ray ได้ </b>ส่งคนไข้ไปรักษาที่ห้องทันตกรรมอื่นได้และทันตแพทย์ล็อกอินเข้าใช้ตามรหัสห้องทันตกรรม <font style={{ color: '#FF5500'}}>ระบบแจ้งเตือนด้วยเสียงกรณีมีคิวใหม่เข้ามา</font>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                            {/* ------------------------------------------------------------------------------- */}
                            </div>
                        </div>
                    </section>
                    <div className="wow fadeInUp col-xs-12 col-sm-12 col-md-4 col-lg-4" style={{float:'center',clear:'both'}}></div>
                    <div className="wow fadeInUp col-xs-12 col-sm-12 col-md-4 col-lg-4" style={{float:'center',clear:'both'}}></div>
                    <p/>   
                    {/* ------------------------------------- END บล๊อก ---------------------------------------------------- */}
                    <section className="home2-section" id="home2">
                        <div className="container">
                              <div className="row">
    
                                <div className="wow fadeInUp col-md-4 col-xs-12 col-sm-12 col-lg-4" data-wow-delay="0.4s">
                                    <div className="about-thumb hvr-glow">
                                        <div className="about-overlay">
    
                                            <div className="card">
                                                <div className="card-header">
                                                    <img className="img mx-auto d-block" src="assets/images/icon-img4.png" alt="" />
                                                </div>
    
                                                <div className="card-body">
                                                    <span className="wow fadeInUp">
                                                        <h5 className="card-title" align="center">การเงิน</h5>
                                                        <span className="textstyle21Kanit" style={{'text-align':'left'}}>
                                                         เก็บเงินและออกใบเสร็จ แบ่งชำระเงิน-ตัดยอดค้าง-มัดจำ แบบการจ่าย(<font style={{ color: '#FF5500' }}>เงินสด</font>,<font style={{color:'#FF5500'}}> เงินโอน</font>,<font style={{color:'#FF5500'}}>QR Pay</font>,<font style={{color:'#FF5500'}}>เครดิต</font>,<font style={{color:'#FF5500'}}>สิทธิ์ประกัน</font>,<font style={{color:'#FF5500'}}>สิทธิ์บัตรทอง</font>) ใบรับรองแพทย์ ใบแจ้งหนี้ ระบบสิทธิ์ผู้ใช้งานการเงิน ออกใบเสร็จได้ 4 แบบเช่น ครึ่งA4-A4-Slip-สิทธ์ประกันเบิกจ่ายและ
                                                         ฉลากยา<font style={{ color: '#FF5500' }}> ใบเสร็จสามารถชำระเงินรวมกันได้ทุกแบบการจ่าย </font>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                                {/* ---------------------------------------------------------------------------------------------- */}
                                <div className="wow fadeInUp col-md-4 col-xs-12 col-sm-12 col-lg-4" data-wow-delay="0.4s">
                                    <div className="about-thumb hvr-glow">
                                        <div className="about-overlay" >
    
                                            <div className="card">
                                                <div className="card-header">
                                                    <img className="mx-auto d-block" src="assets/images/icon-img5.png" alt="" />
                                                </div>
                                                <div className="card-body">
                                                    <span className="wow fadeInUp">
                                                        <h5 className="card-title" align="center">ตารางนัดหมาย</h5>
                                                        <span className="textstyle21Kanit" style={{'text-align':'left'}}>
                                                         บันทึก/แก้ไข/ยกเลิกนัดหมาย เตือนนัดหมาย Recall ปฎิทินนัดหมาย ทำนัดหมายคนไข้ใหม่ที่ยังไม่มีชื่อในระบบ ออกใบนัด สามารถส่งคิวคนไข้ไปห้องทันตกรรมอื่นได้หรือส่งนัดหมายคนไข้ไปยังคลินิกสาขาอื่นๆได้และยังสามารถ<b>ส่ง SMS เข้ามือถือลูกค้าเพื่อเตือนนัดหมาย</b>
                                                         <font style={{color:'#FF5500'}}> ระบบการติดตามคนไข้พร้อมรายงาน</font>    
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                                {/* ---------------------------------------------------------------------------------------------- */}
                                <div className="wow fadeInUp col-md-4 col-xs-12 col-sm-12 col-lg-4" data-wow-delay="0.4s">
                                    <div className="about-thumb hvr-glow">
                                        <div className="about-overlay" >
    
                                            <div className="card">
                                                <div className="card-header">
                                                    <img className="mx-auto d-block" src="assets/images/icon-img6.png" alt="" />
                                                </div>
                                                <div className="card-body">
                                                    <span className="wow fadeInUp">
                                                        <h5 className="card-title" align="center">คลังเวชภัณฑ์</h5>
                                                        <span className="textstyle21Kanit" style={{'text-align':'left'}}>
                                                         บันทึก/แก้ไข/ข้อมูลยาเวชภัณฑ์ การขายหน้าร้านและรับ-เบิกเวชภัณฑ์ซึ่งจะควบคุมเวชภัณฑ์โดยเพิ่มหรือตัดสต๊อกโดยอัตโนมัติ รายงานเวชภัณฑ์ใกล้หมดและที่หมดอายุ รายงานการขายและรับเข้าตามเงื่อนไข 
                                                         <font style={{color:'#FF5500'}}> กำหนดระดับราคาได้ 5 ระดับ</font><b> สามารถสร้างคลังสินค้าย่อยตามจำนวนห้องทันตกรรมเพื่อขายได้ </b>    
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                            {/* ----------------------------------------------- */}
                            </div>  
                            <hr />
                        </div>
                    </section>
                    {/* ------------------------------------- END บล๊อก ---------------------------------------------------- */}  
                    {/* <div className="wow fadeInUp col-xs-12 col-sm-12 col-md-4 col-lg-4" style={{float:'center',clear:'both'}}></div> */}
                    {/* <div className="wow fadeInUp col-xs-12 col-sm-12 col-md-4 col-lg-4" style={{float:'center',clear:'both'}}></div> */}
                    <p/>  
                    {/* ------------------------------------- Screenshot Section ----------------------------------------------- */}
                    <section id="screenshot">
                    <div className="container">
                        <div className="row">
    
                                <div className="container-fluid text-center">
                                    <span className="textstyle10">หน้าจอแอป</span>
                                    <p className="wow fadeInUp" data-wow-delay="0.8s">ตัวอย่างหน้าจอบางส่วนของโปรแกรม <br />
                                    <font style={{color:'Red'}}> ใช้ง่ายครบทุกฟังก์ชัน</font> ประสบการณ์มากกว่า 15 ปี มีใช้ในคลินิกทั่วประเทศ
                                    </p>
                                </div>
                                {/* <!-- Screenshot Owl Carousel --> */}
                                <div className="container justify-content-center">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                        <Slideshow />
                                        </div>
                                    </div>
                                </div>
                                
                        </div>
                        <hr />
                    </div>
                    </section>
                    {/* <!------------------------------- LINE NOTIFY ---------------------------------------------------------> */}
                    {/* <!-- Screenshot Section --> */}
                    <section id="linenotisec">
                        <div className="container">
                            <div className="row">
                                        
                                {/* <div className="col-md-offset-2 col-md-12 col-sm-12" align="center"> */}
                                <div className="container-fluid text-center">    
                                    <div className="section-title">
                                        <span className="textstyle10">แจ้งเตือนรายงานทางไลน์</span>
                                        <p className="wow fadeInUp" data-wow-delay="0.8s">ทำให้เจ้าของคลินิกรับรู้รายงานทุกการเคลื่อนไหวผ่านทาง Line</p>
                                    </div>
                                </div>
    
                                <div id="container_11641a92" className="col-md-12 col-sm-12 text-center">
                                        <img  src="assets/images/linenotify-img.png" width="750" height="296" className="img-fluid" alt="" />
                                </div>  
                                
                            </div>
    
                            <hr />
                        </div>
                    </section>
                    {/* <!---------------------------------------- Report Section --------------------------------> */}
                    <section id="report">
                        <div className="container" align="center">
                            <div className="row">
    
                                <div className="col-md-offset-2 col-md-12 col-sm-12" align="center">
                                    <div className="section-title">
                                        <span className="textstyle10">รายงาน</span><br />
                                        <span className="wow fadeInUp" data-wow-delay="0.8s">ตัวอย่างรายงานต่างๆ</span>
                                        <div className="wow fadeInUp textstyle21" data-wow-delay="0.8s" style={{padding:'5px',align:'left'}}>
                                            เช่น ✓ใบเสร็จรับเงิน ✓ใบรับรองแพทย์ ✓แบบแสดงใช้สิทธิบริการกรณีทันตกรรม ✓ใบแจ้งหนี้ ✓ใบOPD ✓รายงานรายรับหมอ ✓รายงานรายรับคลินิก ✓รายงานรายรับประจำวัน
                                            ✓รายงานยอดเก็บตามใบเสร็จ ✓รายงานค้างชำระ ✓รายงานแล็บ ✓รายงานนัดหมาย ✓รายงานติดตามคนไข้จัดฟัน-ขูดหินปูน-อุดฟัน,ถอนฟัน-รักษารากฟัน-ใส่ฟัน,Implant 
                                            ✓รายงานลูกหนี้ ✓รายงานสต๊อกสินค้า ✓รายงานขาย-รับเข้า-เบิก ✓รายงานสั่งซื้อสินค้า ✓รายงานวิเคราะห์ ✓รายงานกำไร-ขาดทุนประจำปี 
                                            ✓พิมพ์สติกเกอร์ฉลากยาและสติกเกอร์บัตรคนไข้และอื่นๆอีกหลายอย่าง
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-12 text-center">
                                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" align="center">
                                        {/* <!-- Carousel indicators --> */}
                                        <ol className="carousel-indicators">
                                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
                                        </ol>
                                        {/* <!------------ The slideshow Picture -------> */}
                                        <div className="carousel-inner bg-dark text-center" align="center">
                                          <div class="row justify-content-center">  
                                                {/* 1-8 */}
                                                <div className="carousel-item active text-center" style={{width:'500px'}}>
                                                    <h5 style={{padding:'5px'}}><font style={{ color:'#40D6EF'}}> ใบเสร็จรับเงิน(ครึ่ง A4) </font></h5>
                                                    <img className="img-fluid d-block w-100 hvr-push" src="assets/UserFiles/Report/RECEIPT.jpg" alt="" />
                                                </div>
                                                <div className="carousel-item text-center" style={{width:'500px'}}>
                                                    <h5 style={{padding:'5px'}}><font style={{ color:'#40D6EF'}}> ใบเสร็จรับเงิน(A4) </font></h5>
                                                    <img className="img-fluid d-block w-100 hvr-push" src="assets/UserFiles/Report/RECEIPT_A4.jpg" alt="" />
                                                </div>
                                                <div className="carousel-item text-center" style={{width:'500px'}}>
                                                    <h5 style={{padding:'5px'}}><font style={{ color:'#40D6EF'}}>  ใบเสร็จรับเงิน(สลิป) 2 ภาษา(Th/En) </font></h5>
                                                    <img className="img-fluid d-block w-100 hvr-push" src="assets/UserFiles/Report/RECEIPT_SLIP.jpg" alt="" />
                                                </div>
                                                <div className="carousel-item text-center" style={{width:'500px'}}>
                                                    <h5 style={{padding:'5px'}}><font style={{ color:'#40D6EF'}}> ใบรับรองแพทย์ 2 ภาษา(Th/En)</font></h5>
                                                    <img className="img-fluid d-block w-100 hvr-push" src="assets/UserFiles/Report/Med_certificate.jpg" alt="" />
                                                </div>
                                                <div className="carousel-item text-center" style={{width:'500px'}}>
                                                    <h5 style={{padding:'5px'}}><font style={{ color:'#40D6EF'}}> แบบใช้สิทธิกรณีทันตกรรม </font></h5>
                                                    <img className="img-fluid d-block w-100 hvr-push" src="assets/UserFiles/Report/SSOSRF.jpg" alt="" />
                                                </div>
                                                <div className="carousel-item text-center" style={{width:'500px'}}>
                                                    <h5 style={{padding:'5px'}}><font style={{ color:'#40D6EF'}}> OPD.Card </font></h5>
                                                    <img className="img-fluid d-block w-100 hvr-push" src="assets/UserFiles/Report/OPD.jpg" alt="" />
                                                </div>
                                                {/* <div className="carousel-item text-center" style={{width:'500px'}}>
                                                    <h5 style={{padding:'5px'}}><font style={{ color:'#40D6EF'}}> ใบต่อ OPD.Card </font></h5>
                                                    <img className="img-fluid d-block w-100 hvr-push" src="assets/UserFiles/Report/OPD_1.jpg" alt="" />
                                                </div> */}
                                                <div className="carousel-item text-center" style={{width:'500px'}}>
                                                    <h5 style={{padding:'5px'}}><font style={{ color:'#40D6EF'}}> รายรับประจำวัน(ตามคิว/ตามหมอ) </font></h5>
                                                    <img className="img-fluid d-block w-100 hvr-push" src="assets/UserFiles/Report/TREATMENT_DAY.jpg" alt="" />
                                                </div>
                                                <div className="carousel-item text-center" style={{width:'500px'}}>
                                                    <h5 style={{padding:'5px'}}><font style={{ color:'#40D6EF'}}> รายรับทันตแพทย์ </font></h5>
                                                    <img className="img-fluid d-block w-100 hvr-push" src="assets/UserFiles/Report/DF.jpg" alt="" />
                                                </div>

                                                <br />
                                                <br />

                                            </div>
                                        </div>
    
                                        {/* <!------------- Left and right control ---------> */}
                                        <div>
                                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                        {/* <!------------------------------------------------------------------------------------------------> */}
                                        </div>
                                    </div>
    
                                </div>
    
                            </div>

                            <hr />
                        </div>
                    </section>
                    {/* ---------------------------- เส้นกั้น ------------------------------------- */}

                    <p/>
                    {/* <!-------------------------- Youtube Preview --------------------------> */}
                    {/* <!-- Screenshot Section --> */}
                    <section id="sdent-preview">
                        <div className="container">
                            <div className="row">

                            <div className="container-fluid text-center"> 
                            {/* <!-- 16:9 aspect ratio --> */}
                            <div className="embed-responsive embed-responsive-16by9">
                                {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/8xl5vyZnRCQ?si=uRtTDtdsxNM_VNV0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                                {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/aMlFcBP7-WQ?si=Epk6iWGR5sTjT9vT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/aMlFcBP7-WQ?si=0xlk_hxMcrNJoiA0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>    
                            </div>

                            </div>
                            <hr />
                        </div>
                    </section>        
                    {/* ---------------------------- เส้นกั้น ------------------------------------- */}

                    {/* ------------------------ รายชื่อลูกค้า ----------------------------- */}
                    <section>
                    {/* GRID CSS */}  
                        <div className="col-md-offset-2 col-md-12 col-sm-12" align="center">
                            <div className="section-title">
                                <span className="textstyle10" align="center">รายชื่อลูกค้า</span>
                                <p className="wow fadeInUp" data-wow-delay="0.8s" align="center">ตัวอย่างลูกค้าที่ใช้โปรแกรมคลินิกทันตกรรม</p>
                            </div>
                        </div>
                        <div className="container">    
                            <div className="grid-container">
                                <div className="grid-items"> 
                                    {/* โลโก้ กลุ่ม 1 */}
                                    <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\HappySmile.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\DentalLand.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\ChacrisClinic.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\NightSmile.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\KDC-Dental.png" alt="" />
                                        </div>    
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\WeDent.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\Luxyim.png" alt="" />
                                        </div>
                                    </Carousel>  
                                </div>

                                <div className="grid-items">
                                    {/* โลโก้ กลุ่ม 2 */}
                                    <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >    
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\MeanburiClinic.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\LeGrande.png" alt="" />    
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\mmt.png" alt="" />   
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\DrffDentalClinic.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\PSmile.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\IDentist.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic\MondPia.png" alt="" />
                                        </div>
                                    </Carousel>
                                </div>
                                <div className="grid-items">
                                    {/* โลโก้ กลุ่ม 3 */}
                                    <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample1.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample2.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample3.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample4.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample5.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample6.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample7.png" alt="" />
                                        </div>
                                    </Carousel>   
                                </div>
                                <div className="grid-items">
                                    {/* โลโก้ กลุ่ม 4 */}    
                                    <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample8.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample9.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample10.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample11.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample12.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample13.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample14.png" alt="" />
                                        </div>
                                    </Carousel>
                                </div>
                                <div className="grid-items">
                                    {/* โลโก้ กลุ่ม 5 */}    
                                    <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample15.png" alt="คลินิกทันตกรรมพรีม่า เดนทัลแคร์" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample16.png" alt="" />
                                        </div>                                        
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample17.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample18.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample19.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic1\CutSample20.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample21.png" alt="" />
                                        </div>
                                    </Carousel>
                                </div>
                                <div className="grid-items">
                                    {/* โลโก้ กลุ่ม 6 */}    
                                    <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample22.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample23.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample24.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample25.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample26.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample27.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample28.png" alt="" />
                                        </div>
                                    </Carousel>
                                </div>
                                <div className="grid-items">
                                    {/* โลโก้ กลุ่ม 7 */}    
                                    <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample29.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample30.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample31.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample32.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample33.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample34.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample35.png" alt="" />
                                        </div>
                                    </Carousel>
                                </div>
                                <div className="grid-items">
                                    {/* โลโก้ กลุ่ม 8 */}    
                                    <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample36.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample37.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample38.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample39.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample40.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample41.png" alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img" src="assets\rc_images\LogoClinic2\CutSample42.png" alt="" />
                                        </div>
                                    </Carousel>
                                </div>
                                
                            </div>
                        </div>
                    {/* END GRID CSS */} 
                    </section>        

                    {/* <p/> */}
    
              </> 
        )
    }
    
}

export default Home
